import { render, staticRenderFns } from "./member.vue?vue&type=template&id=c89f9eac&scoped=true&"
import script from "./member.vue?vue&type=script&lang=babel&"
export * from "./member.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c89f9eac",
  null
  
)

export default component.exports